import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import AppContext from '../../src/AppContext';
import { LOGGED_IN_ONLY_URL, LOGGED_OUT_ONLY_URL, URL } from '../../src/config';
import { api_post, cleanString, cookieDelete, cookieGet } from '../../src/utils';

/**
 * This component handles:
 * Auth
 * Logged in routing
 * Logged out routing
 * It must exist as a HOC over children of the Layout component
 */

export default function AuthRouter({ children }) {
  const { user, setUser } = useContext(AppContext);
  const router = useRouter()

  // We use this to prevent the temporary flashing of a route that we will redirect the user away from
  const [goingToChangeRoute, setGoingToChangeRoute] = useState(true)

  useEffect(async () => {
    setGoingToChangeRoute(false)

    // Get jwt token from cookies (user login info, signed by the server)
    const jwt = cookieGet("jwt")

    // If the user info already exists within the context, no need to do more requests to the server
    // In that case we just return without further logic
    if (jwt && user) {
      // if logged in, and trying to access a logged-out only page (such as login page)
      if (LOGGED_OUT_ONLY_URL.includes(router.pathname)) {
        setGoingToChangeRoute(true) // prevent flashing
        router.push(URL.WEB.INDEX); // exit
      }
      else {
        return; // exit, do nothing, just return children
      }
    }

    // Must be a logged in user to continue
    if (!jwt) {
      setUser(null);

      // if no jwt, then user isn't authorized to access certain pages, send user to index page
      // The 2nd case with cleanString handles the pages with dynamic routes, example: view-job/1
      if (LOGGED_IN_ONLY_URL.includes(router.pathname) || LOGGED_IN_ONLY_URL.includes(cleanString(router.pathname, "\\[\\[?\\.?\\.?\\.?id\\]\\]?"))) {
        setGoingToChangeRoute(true) // prevent flashing
        router.push(URL.WEB.LOGIN); // exit
      }
    }
    // If we have jwt, but no user object in App Context, then we'll connect to ther server to get user info and store in app context
    // This should happen only once in app lifetime (on first page render by a returning user)
    else {
      // Initial values are empty
      var success = false
      var userInfo = null

      // Check the jwt with the server, if valid, get the user info and store in the app context
      // On failture, delete the jwt, forcing user to relogin
      try {
        var res = await api_post(URL.API.USER_AUTH, { jwt: jwt })

        success = res.data.success
        userInfo = res.data.user
      } catch (error) {
        success = false
        userInfo = null
      }

      // If failure, delete jwt for security
      if (!success) {
        cookieDelete("jwt");
      }

      // Store this user's info inside the app context, so it's accessable inside the entire component tree
      setUser(userInfo)
    }
  }, [router.pathname, user])

  return (
    <>
      {!goingToChangeRoute && children}
    </>
  )
}