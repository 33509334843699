import * as React from 'react';
import { useContext, useEffect } from "react";

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArticleIcon from '@mui/icons-material/Article';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from "@mui/material/useMediaQuery";

// Local
import { useRouter } from 'next/router';
import AppContext from "../../src/AppContext";
import { URL, ENUM_USERTYPE } from '../../src/config';
import Link from '../../src/Link';
import UserAvatar from '../User/UserAvatar';

const commonHeaderPages = [

]

const professionalPages = [
    { title: "Search Jobs", url: URL.WEB.SEARCH_JOB_POSTS, icon: <WorkIcon /> },
    { title: "My Profile", url: URL.WEB.PROFILE(""), icon: <AccountBoxIcon /> },
    { title: "My Job Applications", url: URL.WEB.MY_JOB_APPLICATIONS, icon: <ArticleIcon /> },
]

const employerPages = [
    { title: "Post a Job", url: URL.WEB.POST_JOB, icon: <PostAddIcon /> },
    { title: "My Job Posts", url: URL.WEB.MY_JOB_POSTS, icon: <ArticleIcon /> },
    { title: "Search Jobs", url: URL.WEB.SEARCH_JOB_POSTS, icon: <WorkIcon /> },
    { title: "My Profile", url: URL.WEB.PROFILE(""), icon: <AccountBoxIcon /> },
]

const loggedInUserMenuPages = [
    { title: "My Profile", url: URL.WEB.PROFILE(""), icon: <AccountBoxIcon /> },
    { title: "Edit Account", url: URL.WEB.EDIT_ACCOUNT, icon: <SettingsIcon /> },
    { title: "Sign Out", url: URL.WEB.LOGOUT, icon: <LogoutIcon /> },
]

// 

function LoggedOutUserButtons() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid sx={{ flexGrow: 0, display: 'flex', justifyContent: "center", gap: 2 }}>
            <Button href={URL.WEB.LOGIN} variant={isMobile ? "contained" : "outlined"} color={isMobile ? "secondary" : "white"} sx={{ textTransform: "none" }}>Sign In</Button>
            <Button href={URL.WEB.SIGNUP} variant="contained" color="secondary" sx={{ textTransform: "none", display: { xs: 'none', md: 'flex' } }}>Create Account</Button>
        </Grid>
    )
}

function LoggedInUserMenu({ anchorElUser, setAnchorElUser }) {
    const router = useRouter();

    // This is used route the user if he clicks on the menu item but not the <Link> element inside of it
    const onMenuItemClick = (event, url) => {
        setAnchorElUser(null);
        router.push(url);
    };

    return (
        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => setAnchorElUser(null)}
        >
            {loggedInUserMenuPages.map((setting) => (
                <MenuItem key={setting.title} onClick={(event) => onMenuItemClick(event, setting.url)}>
                    {setting.icon}

                    <Link ml={1}
                        href={setting.url}
                        color="inherit"
                        underline="none"
                    >
                        {setting.title}
                    </Link>
                </MenuItem>
            ))}
        </Menu>
    )
}

function HeaderButton({ title, url, icon }) {
    return (
        <Link
            textAlign="left"
            href={url}
            color="inherit"
            underline="none">
            <Button
                variant="text"
                color="white"
                startIcon={icon}
                sx={{
                    textTransform: "none",
                    width: '100%',
                    color: 'white.main',
                    fontSize: '110%',
                    justifyContent: 'left',
                }}>
                {title}
            </Button>
        </Link>
    )
}

export default function Header({ isDrawerOpen, setIsDrawerOpen }) {
    const { user, setUser } = useContext(AppContext);
    const theme = useTheme();

    // User menu
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    // reload if user changes
    useEffect(() => { }, [user]);

    return (
        // zIndex and position are needed to be above the SideDrawer
        <AppBar position="static" color="primary" sx={{ height: "fit-content", zIndex: theme.zIndex.drawer + 1, position: 'relative' }} >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Mobile & Desktop: Side Drawer Button */}
                    <Box >
                        <IconButton size="large" onClick={() => { setIsDrawerOpen((open) => !open); }} color="inherit">
                            <MenuIcon />
                        </IconButton>
                    </Box>

                    {/* Mobile & Desktop: Logo image with link to home */}
                    <Link
                        href={URL.WEB.INDEX}
                        color="inherit"
                        sx={{ display: { xs: 'flex', md: 'flex' }, flexGrow: { xs: 1, md: 0 }, mx: { xs: 2 }, my: 1 }}>
                        <img src={"/img/logo.png"} alt="LOGO" height='50px' />
                    </Link>

                    {/* Desktop only: header pages */}
                    <Grid sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "center", gap: 4 }}>
                        {commonHeaderPages.map((page) => (
                            <Link
                                key={page.title}
                                href={page.url}
                                color="inherit"
                                underline="none"
                            >
                                <HeaderButton key={page.title} url={page.url} title={page.title} icon={page.icon} />
                            </Link>
                        ))}

                        {user && (user.type == ENUM_USERTYPE.PROFESSIONAL ? professionalPages : employerPages).map((page) => (
                            <HeaderButton key={page.title} url={page.url} title={page.title} icon={page.icon} />
                        ))}
                    </Grid>

                    {/* Mobile & Desktop: Logged in user-menu with avatar */}
                    {user &&
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={(event) => setAnchorElUser(event.currentTarget)} sx={{ p: 0 }}>
                                    <UserAvatar user={user} size={50} />
                                </IconButton>
                            </Tooltip>

                            <LoggedInUserMenu anchorElUser={anchorElUser} setAnchorElUser={setAnchorElUser} />
                        </Box>
                    }

                    {/* Mobile & Desktop: Logged out user SignUp/Login buttons */}
                    {!user &&
                        <LoggedOutUserButtons></LoggedOutUserButtons>
                    }
                </Toolbar>
            </Container>
        </AppBar >
    );
};