import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArticleIcon from '@mui/icons-material/Article';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '../../src/Link';

import { useMediaQuery, useTheme } from '@mui/material';
import AppContext from 'AppContext';
import { useContext, useEffect } from 'react';
import { URL, ENUM_USERTYPE } from '../../src/config';

const generalPages = [
    { title: "About", url: URL.WEB.ABOUT, icon: <WorkIcon /> },
    { title: "Privacy Policy", url: URL.WEB.PRIVACY_POLICY, icon: <WorkIcon /> },
    { title: "Contact Us", url: URL.WEB.CONTACT_US, icon: <WorkIcon /> },
]

const professionalPages = [
    { title: "Search Jobs", url: URL.WEB.SEARCH_JOB_POSTS, icon: <WorkIcon /> },
    { title: "My Job Applications", url: URL.WEB.MY_JOB_APPLICATIONS, icon: <ArticleIcon /> },
]

const employerPages = [
    { title: "Post a Job", url: URL.WEB.POST_JOB, icon: <PostAddIcon /> },
    { title: "My Job Posts", url: URL.WEB.MY_JOB_POSTS, icon: <ArticleIcon /> },
    { title: "Search Jobs", url: URL.WEB.SEARCH_JOB_POSTS, icon: <WorkIcon /> },
]

const loggedInPages = [
    { title: "My Profile", url: URL.WEB.PROFILE(""), icon: <AccountBoxIcon /> },
    { title: "Edit Account", url: URL.WEB.EDIT_ACCOUNT, icon: <SettingsIcon /> },
    { title: "Sign Out", url: URL.WEB.LOGOUT, icon: <LogoutIcon /> },
]

const loggedOutPages = [
    { title: "Sign In", url: URL.WEB.LOGIN, icon: <LoginIcon /> },
    { title: "Create Account", url: URL.WEB.SIGNUP, icon: <AccountBoxIcon /> },
]

function FooterSection({ title, user, pages }) {
    return (
        <Stack justifyContent="center" spacing={0.5} width={"100%"}>
            <Typography variant="body1" color="white.main" fontWeight="bold">{title}</Typography>
            {
                pages.length > 0 &&
                pages.map((page) => (
                    <Link key={page.title} href={page.url} color="lightGray.main" underline="none" py={0.5} my={0.5}>{page.title}</Link>
                ))
            }
        </Stack>)
}

export default function Footer({ children }) {
    const { user, setUser } = useContext(AppContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // reload if user changes
    useEffect(() => { }, [user]);

    return (
        <>
            <footer style={{
                width: '100%',
                marginTop: '4rem',
            }}>
                <Grid container wrap="wrap"
                    sx={{
                        justifyContent: "center",
                        gap: 10,
                        p: 4,
                        bgcolor: "#262626"
                    }}>
                    {/* Account */}
                    <Grid item flex={isMobile ? 1 : "none"}>
                        <FooterSection title={"Account"} user={user} pages={user ? loggedInPages : loggedOutPages} />
                    </Grid>

                    {/* Jobs */}
                    {
                        user &&
                        <Grid item flex={isMobile ? 1 : "none"}>
                            <FooterSection title={"Jobs"} user={user} pages={user.type == ENUM_USERTYPE.EMPLOYER ? employerPages : professionalPages} />
                        </Grid>
                    }

                    {/* General */}
                    <Grid item flex={isMobile ? 1 : "none"}>
                        <FooterSection title={"General"} user={user} pages={generalPages} />
                    </Grid>
                </Grid>
            </footer >
        </>
    )
}