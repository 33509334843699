import { Avatar } from "@mui/material";
import { URL } from '../../src/config';

export default function UserAvatar({ user, size = 50, sx = [] }) {
  return (
    <Avatar
      sx={{
        height: size,
        width: size,
        bgcolor: 'secondary.main',
        ...sx
      }}
      alt={user.display_name}
      src={user.avatar_file_id ? URL.WEB.AVATAR(user.avatar_file_id) : "#"}
    />
  );
}