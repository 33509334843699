import Box from "@mui/material/Box";

import { Grid } from "@mui/material";
import Head from "next/head";
import { useState } from "react";
import AuthRouter from "./AuthRouter";
import Footer from "./Footer";
import Header from "./Header";
import SideDrawer from "./SideDrawer";


export default function Layout({ children, pageTitle = "Untitled", pageDesc = "Search, post and apply for dentist jobs in India.", noContentPadding = false, centerContentVertically = false }) {
    const contentStyle = {}
    contentStyle.px = noContentPadding ? 0 : 4 // padding right/left, horizontal touching of corners
    contentStyle.my = noContentPadding ? '' : 6 // margin top/bottom

    if (centerContentVertically) {
        contentStyle.display = "flex"
        contentStyle.justifyContent = "center";
        contentStyle.alignItems = "center";
    }

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    return (
        <AuthRouter>
            <Head>
                <title>{pageTitle}</title>
                <meta property="og:title" content={`${pageTitle}`} key="title" />
                <meta name="title" content={`${pageTitle}`} />
                <meta name="description" content={`${pageDesc}`} />
            </Head>

            <Grid sx={{
                minHeight: '100vh',
                display: 'grid',
                gridAutoRows: "minmax(min-content, max-content)",
                gridTemplateRows: "auto 1fr auto",
                gridTemplateAreas:
                    `"header"
                    "main"
                    "footer"`,
            }}>
                {/* Nav Bar */}
                <Header sx={{ gridArea: "header" }} setIsDrawerOpen={setIsDrawerOpen} />

                {/* Side Drawer */}
                <SideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />

                {/* Page Content */}
                <Box sx={{ ...contentStyle, gridArea: "main", width: '100vw' }}>
                    {children}
                </Box>

                {/* Footer */}
                < Footer sx={{ gridArea: "footer" }} />
            </Grid >
        </AuthRouter >
    )
}
