// #region Config

import { ENV_ID } from "ENV_ID"

export const IS_TEST_ENVIRONMENT = ENV_ID.includes("TEST");
export const IS_DEV_ENVIRONMENT = ENV_ID == "TEST_DEV";
export const URL_API_ROOT = IS_DEV_ENVIRONMENT ? "http://127.0.0.1:5000/api" : "/api"
export const URL_SERVER_UPLOADS_ROOT = IS_DEV_ENVIRONMENT ? "http://127.0.0.1:5000/api/uploads" : "/api/uploads"
export const URL_SERVER_JSON_ROOT = IS_DEV_ENVIRONMENT ? "http://127.0.0.1:5000/api/json" : "/api/json"

export const WEBSITE_NAME = "Dental Options"
export const WEBSITE_URL = "https://dentaloptions.in"
export const CONTACT_EMAIL = "admin@dentaloptions.in"

export const ERROR_TEXT = {
    RESOURCE_ERROR: "Error: failed to load the required resource.",
}

export const URL = {
    TITLE: {
        INDEX: WEBSITE_NAME,
        SIGNUP: `${WEBSITE_NAME}: Sign up`,
        LOGIN: `${WEBSITE_NAME}: Sign in`,
        LOGOUT: `${WEBSITE_NAME}: Logout`,

        ACCOUNT: `${WEBSITE_NAME}: Account Settings`,
        PASSWORD_CHANGE: `${WEBSITE_NAME}: Change Password`,
        EMAIL_CHANGE: `${WEBSITE_NAME}: Change Email`,
        PASSWORD_RESET_REQUEST: `${WEBSITE_NAME}: Request Password Change`,
        PASSWORD_RESET: `${WEBSITE_NAME}: Reset Password`,

        PROFILE: `${WEBSITE_NAME}: User Profile`,

        SEARCH_JOB_POSTS: `${WEBSITE_NAME}: Search Jobs`,
        POST_JOB: `${WEBSITE_NAME}: Post a Job`,
        EDIT_JOB_POST: `${WEBSITE_NAME}: Edit Job`,
        VIEW_JOB_POST: `${WEBSITE_NAME}: View Job`,
        MY_JOB_POSTS: `${WEBSITE_NAME}: My Job Posts`,
        APPLY_TO_JOB: `${WEBSITE_NAME}: Apply to Job`,
        VIEW_JOB_APPLICATION: `${WEBSITE_NAME}: View Job Application`,
        VIEW_JOB_APPLICATIONS: `${WEBSITE_NAME}: View Job Post Application`,
        MY_JOB_APPLICATIONS: `${WEBSITE_NAME}: My Job Applications`,

        AVATAR: `${WEBSITE_NAME}: User Avatar`,
        RESUME: `${WEBSITE_NAME}: User Resume`,

        ABOUT: `${WEBSITE_NAME}: About`,
        PRIVACY_POLICY: `${WEBSITE_NAME}: Privacy Policy`,
        CONTACT_US: `${WEBSITE_NAME}: Contact Us`,
    },
    WEB: {
        INDEX: "/",
        SIGNUP: "/signup",
        LOGIN: "/login",
        LOGOUT: "/logout",

        EDIT_ACCOUNT: "/account/edit",
        PASSWORD_CHANGE: "/account/change-password",
        EMAIL_CHANGE: "/account/change-email",
        PASSWORD_RESET_REQUEST: "/account/request-password-reset",
        PASSWORD_RESET: "/account/reset-password",

        PROFILE: id => `/profile/${id}`,

        SEARCH_JOB_POSTS: "/search-jobs",
        POST_JOB: "/post-job",
        EDIT_JOB_POST: id => `/edit-job/${id}`,
        VIEW_JOB_POST: id => `/job/${id}`,
        MY_JOB_POSTS: "/my-job-posts",
        APPLY_TO_JOB: id => `/apply-to-job/${id}`,
        VIEW_JOB_APPLICATION: id => `/job-application/${id}`,
        VIEW_JOB_APPLICATIONS: id => `/view-job-applications/${id}`,
        MY_JOB_APPLICATIONS: "/my-job-applications",

        AVATAR: file_name => `${URL_SERVER_UPLOADS_ROOT}/avatar/${file_name}`,
        RESUME: file_name => `${URL_SERVER_UPLOADS_ROOT}/resume/${file_name}`,

        STATES_FILE: `${URL_SERVER_JSON_ROOT}/states_cities.json`,

        ABOUT: "/about",
        PRIVACY_POLICY: "/privacy-policy",
        CONTACT_US: "/contact-us",
    },
    API: {
        USER_AUTH: URL_API_ROOT + "/user/auth",
        USER_EXISTS: URL_API_ROOT + "/user/exists",
        SIGNUP: URL_API_ROOT + "/user/signup",
        LOGIN: URL_API_ROOT + "/user/login",

        UPLOAD_RESUME: URL_API_ROOT + "/user/upload-resume",
        UPDATE_RESUME_FILE_ID: URL_API_ROOT + "/user/update-resume-file-id",
        UPDATE_AVATAR: URL_API_ROOT + "/user/upload-avatar",
        PASSWORD_RESET_REQUEST: URL_API_ROOT + "/user/password-reset-request",
        PASSWORD_RESET: URL_API_ROOT + "/user/password-reset",
        GET_ACCOUNT: URL_API_ROOT + "/user/account/get",
        SAVE_ACCOUNT: URL_API_ROOT + "/user/account/update",
        PASSWORD_CHANGE: URL_API_ROOT + "/user/account/change-password",
        EMAIL_CHANGE: URL_API_ROOT + "/user/account/change-email",
        UPDATE_TOGGLE: URL_API_ROOT + "/user/account/update-toggle",

        GET_PROFILE: URL_API_ROOT + "/user/public-profile",

        POST_JOB: URL_API_ROOT + "/job/post",
        EDIT_JOB_POST: URL_API_ROOT + "/job/edit",
        GET_JOB_POST: URL_API_ROOT + "/job/get",
        GET_JOB_POSTS: URL_API_ROOT + "/jobs/get",
        TOGGLE_MY_JOB_POST: URL_API_ROOT + "/job/toggle",
        DELETE_MY_JOB_POST: URL_API_ROOT + "/job/delete",
        CAN_APPLY_TO_JOB: URL_API_ROOT + "/job/can-apply",
        APPLY_TO_JOB: URL_API_ROOT + "/job/apply",
        GET_JOB_APPLICATION: URL_API_ROOT + "/job/application/get",
        GET_JOB_APPLICATIONS: URL_API_ROOT + "/job/employer/applications/get",
        GET_MY_JOB_APPLICATIONS: URL_API_ROOT + "/job/professional/applications/get",
        DELETE_MY_JOB_APPLICATION: URL_API_ROOT + "/job/professional/application/delete",
    }
}

export const LOGGED_IN_ONLY_URL = [
    URL.WEB.EDIT_ACCOUNT,
    URL.WEB.PASSWORD_CHANGE,
    URL.WEB.EMAIL_CHANGE,

    URL.WEB.PROFILE(""),

    URL.WEB.SEARCH_JOB_POSTS,
    URL.WEB.POST_JOB,
    URL.WEB.EDIT_JOB_POST(""),
    URL.WEB.VIEW_JOB_POST(""),
    URL.WEB.MY_JOB_POSTS,
    URL.WEB.APPLY_TO_JOB(""),
    URL.WEB.VIEW_JOB_APPLICATION(""),
    URL.WEB.VIEW_JOB_APPLICATIONS(""),
    URL.WEB.MY_JOB_APPLICATIONS,
]

export const LOGGED_OUT_ONLY_URL = [
    URL.WEB.LOGIN,
    URL.WEB.SIGNUP,
    URL.WEB.PASSWORD_RESET_REQUEST,
    URL.WEB.PASSWORD_RESET,
]

// #endregion

// #region Enums

export const ENUM_USERTYPE = {
    PROFESSIONAL: 1,
    EMPLOYER: 2,
}

export const ENUM_USERPOSITION = {
    CLINIC: "CLINIC",
    DENTIST: "DENTIST",
    DENTIST_STUDENT: "DENTIST_STUDENT",
    DENTAL_ASSISTANT: "DENTAL_ASSISTANT",
    DENTAL_HYGIENIST: "DENTAL_HYGIENIST",
    DENTAL_TECHNICIAN: "DENTAL_TECHNICIAN",
    DENTAL_INTERN: "DENTAL_INTERN",
    DENTAL_SURGEON: "DENTAL_SURGEON",
}

export const ENUM_USERGENDER = {
    MALE: "M",
    FEMALE: "F",
    OTHER: "-"
}

// #endregion

// #region UI Labels & Options

export const LABEL_USER_TYPE_POSITION = {
    [ENUM_USERTYPE.PROFESSIONAL]: "Professional",
    [ENUM_USERTYPE.EMPLOYER]: "Employer",
    [ENUM_USERPOSITION.CLINIC]: "Clinic",
    [ENUM_USERPOSITION.DENTIST]: "Dentist",
    [ENUM_USERPOSITION.DENTIST_STUDENT]: "Dentistry Student",
    [ENUM_USERPOSITION.DENTAL_ASSISTANT]: "Dental Assistant",
    [ENUM_USERPOSITION.DENTAL_HYGIENIST]: "Dental Hygienist",
    [ENUM_USERPOSITION.DENTAL_TECHNICIAN]: "Dental Technician",
    [ENUM_USERPOSITION.DENTAL_INTERN]: "Dental Intern",
    [ENUM_USERPOSITION.DENTAL_SURGEON]: "Dental Surgeon",
};

export const OPTIONS_USER_GENDER = {
    [ENUM_USERGENDER.MALE]: "Male",
    [ENUM_USERGENDER.FEMALE]: "Female",
    [ENUM_USERGENDER.OTHER]: "Other",
}

export const OPTIONS_PROFESSIONAL_POSITION = {
    [ENUM_USERPOSITION.DENTIST]: "Dentist",
    [ENUM_USERPOSITION.DENTIST_STUDENT]: "Dentistry Student",
    [ENUM_USERPOSITION.DENTAL_ASSISTANT]: "Dental Assistant",
    [ENUM_USERPOSITION.DENTAL_HYGIENIST]: "Dental Hygienist",
    [ENUM_USERPOSITION.DENTAL_TECHNICIAN]: "Dental Technician",
    [ENUM_USERPOSITION.DENTAL_INTERN]: "Dental Intern",
    [ENUM_USERPOSITION.DENTAL_SURGEON]: "Dental Surgeon"
}

// #endregion