
// this file has the KEY value of ENV_ID, avoid changing unless you know what you're doing
//we use to switch between different environments

// TEST_DEV: Development env
// TEST_UBER: Uber space test
// TEST_ONLINE: online vps testing
// PROD: online production

// MUST CHANGE ON PROD
export const ENV_ID = "PROD" // TEST_DEV, TEST_UBER,, TEST_ONLINE, PROD
// MUST CHANGE ON PROD