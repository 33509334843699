import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ArticleIcon from '@mui/icons-material/Article';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import { Button, Divider, Drawer } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect } from "react";

import AppContext from "../../src/AppContext";
import Link from "../../src/Link";
import { URL, ENUM_USERTYPE } from "../../src/config";

const commonPages = [

]

const professionalPages = [
    { title: "Search Jobs", url: URL.WEB.SEARCH_JOB_POSTS, icon: <WorkIcon /> },
    { title: "My Job Applications", url: URL.WEB.MY_JOB_APPLICATIONS, icon: <ArticleIcon /> },
]

const employerPages = [
    { title: "Post a Job", url: URL.WEB.POST_JOB, icon: <PostAddIcon /> },
    { title: "My Job Posts", url: URL.WEB.MY_JOB_POSTS, icon: <ArticleIcon /> },
    { title: "Search Jobs", url: URL.WEB.SEARCH_JOB_POSTS, icon: <WorkIcon /> },
]

const loggedInPages = [
    { title: "My Profile", url: URL.WEB.PROFILE(""), icon: <AccountBoxIcon /> },
    { title: "Edit Account", url: URL.WEB.EDIT_ACCOUNT, icon: <SettingsIcon /> },
    { title: "Sign Out", url: URL.WEB.LOGOUT, icon: <LogoutIcon /> },
]

const loggedOutPages = [
    { title: "Sign In", url: URL.WEB.LOGIN, icon: <LoginIcon /> },
    { title: "Create Account", url: URL.WEB.SIGNUP, icon: <AccountBoxIcon /> },
]

function SideBarLink({ title, url, icon, setIsDrawerOpen }) {
    return (
        <Link
            textAlign="left"
            href={url}
            color="inherit"
            underline="none"
            sx={{ width: '100%' }}
            onClick={() => setIsDrawerOpen(false)}>
            <Button
                variant="text"
                startIcon={icon}
                sx={{
                    textTransform: "none",
                    width: '100%',
                    color: 'white.main',
                    fontSize: '110%',
                    justifyContent: 'left',
                }}>
                {title}
            </Button>

        </Link>
    )
}

export default function SideDrawer({ isDrawerOpen, setIsDrawerOpen }) {
    const theme = useTheme()
    const { user, setUser } = useContext(AppContext);

    // reload if user changes
    useEffect(() => { }, [user]);

    return (
        <Drawer
            anchor='left'
            textAlign={'left'}
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
        >
            {/* This is necessary to push content underthe appbar */}
            <dix style={{ ...theme.mixins.toolbar }} />
            <Stack
                width={{ xs: "80vw", sm: "40vw", md: "20vw", lg: "15vw" }}
                height={'100%'}
                alignItems='flex-start'
                role='presentation'
                direction={'column'}
                spacing={2}
                py={4}
                px={2}
                sx={{
                    bgcolor: "primary.main",
                }}
            >

                {commonPages.map((page) => (<SideBarLink key={page.title} title={page.title} url={page.url} icon={page.icon} setIsDrawerOpen={setIsDrawerOpen} />))}
                
                {user &&
                    <>
                        {user.type == ENUM_USERTYPE.EMPLOYER && employerPages.map((page) => (<SideBarLink key={page.title} title={page.title} url={page.url} icon={page.icon} setIsDrawerOpen={setIsDrawerOpen} />))}
                        {user.type == ENUM_USERTYPE.PROFESSIONAL && professionalPages.map((page) => (<SideBarLink key={page.title} title={page.title} url={page.url} icon={page.icon} setIsDrawerOpen={setIsDrawerOpen} />))}

                        <Divider flexItem />
                    </>
                }
                
                {user
                    ? loggedInPages.map((page) => (<SideBarLink key={page.title} title={page.title} url={page.url} icon={page.icon} setIsDrawerOpen={setIsDrawerOpen} />))
                    : loggedOutPages.map((page) => (<SideBarLink key={page.title} title={page.title} url={page.url} icon={page.icon} setIsDrawerOpen={setIsDrawerOpen} />))
                }
            </Stack>
        </Drawer>
    )
}
