import axios from 'axios';
import cookieCutter from 'cookie-cutter'
import Cookies from 'cookies'

// #region API

export function api_post(url, data) {
    return axios.post(url, data)
}

// #region Client-only

const next_month = new Date(new Date().setMonth(new Date().getMonth() + 1));

export function cookieGet(k) {
    return cookieCutter.get(k)
}

export function cookieSet(k, v, expires = null) {
    cookieCutter.set(k, v, { expires: expires || next_month, path: "/" })
}

export function cookieDelete(k) {
    cookieCutter.set(k, null, { expires: new Date(0, 0), path: "/" })
}

// #endregion


// #region Server-only

export function server_CookieGet(ctx, name) {
    const cookies = new Cookies(ctx.req, ctx.res)
    return cookies.get(name)
}

// #endregion

// #region Helpers

export function relativeTimeFromDateTimeStr(dateTimeStr) {
    if (!dateTimeStr) {
        return ''
    }

    return relativeTimeSimple(new Date(dateTimeStr).getTime() / 1000);
}

export function relativeTimeSimple(utcTimestamp) {
    if (!utcTimestamp) {
        return ''
    }

    // Get current utc timestamp
    const currentUtcTimestamp = Math.floor(new Date().getTime() / 1000);

    // Calc difference
    const difference = currentUtcTimestamp - utcTimestamp;

    let relativeTime = ``;

    if (difference < 60) {
        // Less than a minute has passed:
        relativeTime = `${difference} second${difference > 1 ? 's' : ''} ago`;
    } else if (difference < 3600) {
        // Less than an hour has passed:
        const minutes = Math.floor(difference / 60);
        relativeTime = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (difference < 86400) {
        // Less than a day has passed:
        const hours = Math.floor(difference / 3600);
        relativeTime = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (difference < 2620800) {
        // Less than a month has passed:
        const days = Math.floor(difference / 86400);
        relativeTime = `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (difference < 31449600) {
        // Less than a year has passed:
        const months = Math.floor(difference / 2620800);
        relativeTime = `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
        // More than a year has passed:
        const years = Math.floor(difference / 31449600);
        relativeTime = `${years} year${years > 1 ? 's' : ''} ago`;
    }

    return relativeTime;
}

export function fillerText(length = 250) {
    return generateString(length)
}

export function generateString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function cleanString(input, pattern) {
    const regexPattern = new RegExp(pattern);
    const cleanedString = input.replace(regexPattern, "");

    return cleanedString;
}

export function flipBinary(value) {
    if (value === 1) {
        return 0;
    } else if (value === 0) {
        return 1;
    } else {
        return null; // or throw an error, depending on your requirements
    }
}

// #endregion

// #region Log

export function log(info, dialog = false) {
    console.log(info)

    if (dialog) {
        alert(info)
    }
}

// #endregion